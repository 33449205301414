import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, Typography, DialogTitle, DialogContent, Dialog, Button, Tabs, Tab, Select, MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import FileCopy from '@material-ui/icons/FileCopy';
import { getAppInfo } from '../../store/selectors/appSelectors';
import BaseButton from '../material-ui/BaseButton';
import { get } from 'lodash';

const url = 'https://exp-scraping-server.azurewebsites.net';

function ExpHierarchy({ appId }) {
  const [showDialog, setShowDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [expHierarchy, setExpHierarchy] = useState(null);
  const [lastUpdatedExp, setLastUpdatedExp] = useState(null);

  // Store separate data for different asset tools
  const [assetData, setAssetData] = useState({
    get_asset_tree: { hierarchy: null, lastUpdated: null },
    get_big_assets: { hierarchy: null, lastUpdated: null },
  });

  const [selectedAssetType, setSelectedAssetType] = useState('get_big_assets');

  const fetchData = (endpoint, setData, setLastUpdated) => {
    fetch(`${url}/${endpoint}?root=${appId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'processing') {
          setData(data.message);
        } else {
          setData(JSON.parse(data.message));
        }
        if (data.last_updated) {
          setLastUpdated(new Date(data.last_updated).toLocaleString());
        }
      })
      .catch(error => console.error(error));
  };

  const updateData = (endpoint, setData, setLastUpdated) => {
    fetch(`${url}/${endpoint}?root=${appId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'processing') {
          setData(data.message);
          setLastUpdated(null);
        } else {
          setData(JSON.parse(data.message));
        }
      })
      .catch(error => console.error(error));
  };

  const getExpHierarchy = () => fetchData('get_exp_tree', setExpHierarchy, setLastUpdatedExp);
  const refreshExpHierarchy = () => updateData('update_exp_tree', setExpHierarchy, setLastUpdatedExp);

  const getAssetHierarchy = () => {
    fetchData(selectedAssetType, 
      (data) => setAssetData(prev => ({
        ...prev,
        [selectedAssetType]: { ...prev[selectedAssetType], hierarchy: data }
      })),
      (lastUpdated) => setAssetData(prev => ({
        ...prev,
        [selectedAssetType]: { ...prev[selectedAssetType], lastUpdated }
      }))
    );
  };

  const refreshAssetHierarchy = () => {
    updateData(selectedAssetType.replace('get_', 'update_'), 
      (data) => setAssetData(prev => ({
        ...prev,
        [selectedAssetType]: { ...prev[selectedAssetType], hierarchy: data }
      })),
      (lastUpdated) => setAssetData(prev => ({
        ...prev,
        [selectedAssetType]: { ...prev[selectedAssetType], lastUpdated }
      }))
    );
  };

  const renderHierarchy = hierarchy => {
    if (!hierarchy) {
      return (
        <Typography>
          Please refresh to get the list.
        </Typography>
      );
    }
    return Object.keys(hierarchy).map(key => (
      <Box key={key} ml={2} mt={1}>
        <Typography variant="subtitle1">{key}</Typography>
        <Box ml={2}>
          {hierarchy[key].map((value, index) => (
            <Typography key={index} variant="body2">{value}</Typography>
          ))}
        </Box>
      </Box>
    ));
  };

  const handleOpenModal = () => {
    getExpHierarchy();
    getAssetHierarchy();
    setShowDialog(true);
  };

  const handleAssetTypeChange = (event) => {
    setSelectedAssetType(event.target.value);
  };

  const currentAssetHierarchy = assetData[selectedAssetType].hierarchy;
  const currentLastUpdatedAsset = assetData[selectedAssetType].lastUpdated;

  return (
    <Box>
      <Typography variant="h6">Experience Hierarchy</Typography>
      <BaseButton color="secondary" size="large" variant="contained" onClick={handleOpenModal}>
        Experience Hierarchy
      </BaseButton>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth PaperProps={{ style: { height: '80vh' } }}>
        <DialogTitle>
          <Tabs value={tabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)}>
            <Tab label="Experience Hierarchy" />
            <Tab label="Assets" />
          </Tabs>
        </DialogTitle>

        <DialogContent>
          {tabIndex === 0 && (
            <Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Button onClick={getExpHierarchy} color="primary" variant="contained" size="small" style={{ marginLeft: 20 }}>Refresh</Button>
                <Button onClick={refreshExpHierarchy} color="primary" variant="contained" size="small" style={{ marginLeft: 20 }}>Update</Button>
                <Button onClick={() => navigator.clipboard.writeText(JSON.stringify(expHierarchy))} color="primary" variant="text" size="small" startIcon={<FileCopy />} style={{ marginLeft: 20 }}>
                  Copy
                </Button>
              </Box>
              {lastUpdatedExp && <Typography>{`Last Updated: ${lastUpdatedExp}`}</Typography>}
              <Box>{typeof expHierarchy === 'object' ? renderHierarchy(expHierarchy) : expHierarchy}</Box>
            </Box>
          )}

          {tabIndex === 1 && (
            <Box>
              <Select value={selectedAssetType} onChange={handleAssetTypeChange}>
                <MenuItem value="get_asset_tree">Experience Wise Asset List</MenuItem>
                <MenuItem value="get_big_assets">List of Assets more than 1MB</MenuItem>
              </Select>

              <Box display="flex" alignItems="center" mb={2} mt={3}>
                <Button onClick={getAssetHierarchy} color="primary" variant="contained" size="small" style={{ marginLeft: 20 }}>Refresh</Button>
                <Button onClick={refreshAssetHierarchy} color="primary" variant="contained" size="small" style={{ marginLeft: 20 }}>Update</Button>
                <Button onClick={() => navigator.clipboard.writeText(JSON.stringify(currentAssetHierarchy))} color="primary" variant="text" size="small" startIcon={<FileCopy />} style={{ marginLeft: 20 }}>
                  Copy
                </Button>
              </Box>
              {currentLastUpdatedAsset && <Typography>{`Last Updated: ${currentLastUpdatedAsset}`}</Typography>}
              <Box>{typeof currentAssetHierarchy === 'object' ? renderHierarchy(currentAssetHierarchy) : currentAssetHierarchy}</Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

ExpHierarchy.propTypes = {
  appId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  appId: getAppInfo(state).id,
});

export default connect(mapStateToProps)(ExpHierarchy);
