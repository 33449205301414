import {
 Add, Delete, FileCopy, Search
} from '@material-ui/icons';
import {
 Box, IconButton, InputAdornment, Menu, MenuItem, Paper, Tooltip, Typography
} from '@material-ui/core';
import { DropTarget } from 'react-dnd';
import { Shortcuts } from 'react-shortcuts/lib';
import { SortableTreeWithoutDndContext as SortableTree, walk as walkTree } from 'react-sortable-tree';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getActions } from '@enklu/server-api';
import { withRouter } from 'react-router';
import { withStyles, withTheme } from '@material-ui/styles';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid';

import { toggleNode as _toggleNode } from '../../store/actions/inspector/treeActions';
import {
  deleteAction,
  updateAction,
  createAction,
  createDuplicateAction,
  addAssetToElement as _addAssetToElement
} from '../../store/actions/elementActions';
import { elementById, parentByChildId } from '../../util/appHelpers';
import { elementDeepCopy, compareSiblings } from '../../util/elementHelpers';
import { getAllAssetsById, getLibraries } from '../../store/selectors/assetLibrariesSelectors';
import { getAppInfo, getAppIsReadOnly } from '../../store/selectors/appSelectors';
import { getScenes } from '../../store/selectors/scenesSelectors';
import { getTree } from '../../store/selectors/inspector/treeSelectors';
import { isValidRelationship, parseAssetSrc, areArraysShallowEqual } from '../../util/util';
import { log } from '../../util/log';
import { textFieldShadow } from '../../styles/muiTheme';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';
import BaseStandardTextField from '../material-ui/BaseStandardTextField';
import ConfirmationModal from '../modals/MuiConfirmationModal';
import DragDropTypes from '../../features/dragAndDrop/DragDropTypes';
import ElementHierarchyEmptySpace from './ElementHierarchyEmptySpace';
import ElementHierarchyItem from './MuiElementHierarchyItem';
import ElementTypes from '../../constants/ElementTypes';
import txns, { updateScene } from '../../store/actions/TxnManager';
import undoManager from '../../store/actions/UndoManager';
import { getAllScriptsById } from '../../store/selectors/scriptLibrariesSelectors';
import { downloadscript } from '../../store/actions/scriptActions';
import { scriptUrl } from "../inspector/script/ScriptUtilities";

const ROW_HEIGHT = 40;

const { shareassetwithapp } = getActions('trellis');

let lastFocus = Date.now();
const doubleClickDelay = 500;

const userIsEnklu = false;

const linkedComponentMenuItem = {
  title: 'Linked Component', eventKey: ElementTypes.LINKED_COMPONENT
};

const createMenu = [
  { title: 'Asset', eventKey: ElementTypes.CONTENT },
  { title: 'Light', eventKey: ElementTypes.LIGHT },
  { title: 'Kinect', eventKey: ElementTypes.KINECT },
  { title: 'Image Anchor', eventKey: ElementTypes.IMAGE_ANCHOR }
];


const updateSiblings = (treeData) => {
  const actions = [];
  // update sibling sort order of all nodes
  walkTree({
    treeData,
    ignoreCollapsed: false,
    getNodeKey: ({ treeIndex }) => treeIndex,
    callback: ({ node: walkNode, treeIndex: walkIndex }) => {
      if (walkNode.siblingIndex !== walkIndex) {
        walkNode.siblingIndex = walkIndex;
        const doAction = updateAction(walkNode.elementId, ActionSchemaTypes.INT, 'siblingIndex', walkIndex);
        actions.push(doAction);
      }
    }
  });
  return actions;
};

const elementToTree = (data, element, parent, nodesById, scene, selectedElementId, assetsById, expanded) => {
  const assetSrc = element.schema && element.schema.strings ? element.schema.strings.assetSrc : null;
  const [assetId] = parseAssetSrc(assetSrc);
  const asset = assetsById[assetId];
  const name = element.schema && element.schema.strings ? element.schema.strings.name : 'Element';
  const scripts = element.schema && element.schema.strings ? element.schema.strings.scripts : '';
  const {
    id: elementId,
    type: elementType,
    children = [],
    schema: {
      bools: { locked: isLocked = false, visible: isVisible = true, loaded: isLoaded = false } = {},
      ints: { siblingIndex: siblingIndex = 0 } = {}
    } = {}
  } = element;

  data.elementId = elementId;
  data.type = elementType;
  data.sceneId = scene.id;
  data.title = name || '';
  data.scripts = scripts || '';
  data.asset = asset;
  data.expanded = expanded.indexOf(elementId) !== -1; // TODO: some projects: expanded is object
  data.isSelected = elementId === selectedElementId;
  data.isEditable = true;
  data.isRemovable = true;
  data.isLocked = isLocked;
  data.isVisible = isVisible;
  data.isLoaded = isLoaded;
  data.siblingIndex = siblingIndex;
  data.searchStatus = ''; // 'target: element', 'target: asset', 'not target', '' (no search)
  data.text = name;
  data.children = [];
  data.schema = element.schema;
  data.parent = parent;
  data.ancestorIsLocked = parent ? parent.isLocked || parent.ancestorIsLocked : false;

  if (data.isSelected && parent && !parent.expanded) {
    // We go up the tree until we find an element whose parent is expanded.

    let p = parent;
    while (p) {
      if (!p.parent || p.parent.expanded) {
        p.isSelected = true;
        break;
      }
      p = p.parent;
    }
  }

  nodesById[elementId] = data;

  if (children.length) {
    children.sort(compareSiblings);
    // eslint-disable-next-line no-restricted-syntax
    for (const child of children) {
      // don't even show the primary anchor
      if (
        child.type === ElementTypes.WORLD_ANCHOR
        && child.schema
        && child.schema.strings
        && child.schema.strings.tag === 'primary'
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const childData = {};
      data.children.push(childData);

      elementToTree(childData, child, data, nodesById, scene, selectedElementId, assetsById, expanded);
    }
  }
};

/**
 * Displays hierarchy.
 */
class ElementHierarchy extends React.Component {
  static propTypes = {
    // external
    elementId: PropTypes.string,

    // connect()
    isReadOnly: PropTypes.bool.isRequired,
    info: PropTypes.object.isRequired,
    scenes: PropTypes.array.isRequired,
    assetsById: PropTypes.object.isRequired,
    tree: PropTypes.object.isRequired,
    libraries: PropTypes.object.isRequired,
    scriptsById: PropTypes.object.isRequired,

    createElement: PropTypes.func.isRequired,
    duplicateElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    shareAssetWithApp: PropTypes.func.isRequired,
    toggleNode: PropTypes.func.isRequired,
    setBoolean: PropTypes.func.isRequired,
    setVisibility: PropTypes.func.isRequired,
    addAssetToElement: PropTypes.func.isRequired,

    // react-router
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,

    connectDropTarget: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  /**
   * Constructs the object.
   */
  constructor(props) {
    super(props);

    this.state = {
      deleteModal: {
        show: false,
        node: null,
        action: null
      },
      filterString: '',
      createAnchorEl: null,
      matchedNodes: {}
    };
  }

  async matchAsync(node, filter) {
    const { scriptsById } = this.props;
    const parsedFilter = filter.toLowerCase();
    if (node.scripts) {
      const scriptsArr = JSON.parse(node.scripts);
      const scriptPromises = scriptsArr.map(script => fetch(scriptUrl(scriptsById[script.id].uri)));
      const scriptResults = await Promise.all(scriptPromises.map(async res => {
        const result = await res;
        if (result.status === 200) {
          const text = await result.text();
          return text.toLowerCase().includes(parsedFilter);
        }
        return false;
      }));
      const result = scriptResults.some(Boolean);
      return result;
    }
    return false;
  }

  async newMatchedNodes(filterString) {
    const {
      info: { name: appName },
      scenes,
      assetsById,
      tree: { expanded },
      match: {
        params: { elementId: selectedElementId }
      }
    } = this.props;
    const matchedNodes = {};
    let scene;

    const nodesById = {};
    // eslint-disable-next-line no-restricted-syntax
    for (scene of scenes) {
      const sceneTree = {
        title: '[Scene]',
        expanded: true
      };

      elementToTree(sceneTree, scene.elements, undefined, nodesById, scene, selectedElementId, assetsById, expanded);

      // root is not editable or removable
      sceneTree.isEditable = false;
      sceneTree.isRemovable = false;

      // force scene name
      sceneTree.title = `${appName}`;
    }
    const matchPromiseList = Object.keys(nodesById).map(async (elementId) => {
      const node = nodesById[elementId];
      const matchResult = await this.matchAsync(node, filterString);
      if (matchResult) {
        matchedNodes[elementId] = matchResult;
      }
    });
    await Promise.all(matchPromiseList);
    return matchedNodes;
  }

  /**
   * Transform the app datastructure into a json format for the tree
   * component.
   */
  appToTree(selectedElementId) {
    const {
      info: { name: appName },
      scenes,
      assetsById,
      tree: { expanded },
      scriptsById
    } = this.props;

    const { filterString, matchedNodes } = this.state;

    // We use this to create the filtered hierarchy.
    const nodesById = {};

    let scene;

    // eslint-disable-next-line no-restricted-syntax
    for (scene of scenes) {
      const sceneTree = {
        title: '[Scene]',
        expanded: true
      };

      elementToTree(sceneTree, scene.elements, undefined, nodesById, scene, selectedElementId, assetsById, expanded);

      // root is not editable or removable
      sceneTree.isEditable = false;
      sceneTree.isRemovable = false;

      // force scene name
      sceneTree.title = `${appName}`;
    }

    const flatPrunedTree = {};
    const clone = (elementId, { overrideExpand = false, searchStatus = '' }) => {
      let clonedElement = flatPrunedTree[elementId];
      if (!clonedElement) {
        const toClone = nodesById[elementId];
        clonedElement = {
          ...toClone,
          children: [],
          parent: toClone.parent
            ? clone(toClone.parent.elementId, {
                overrideExpand,
                searchStatus: 'not target'
              })
            : undefined
        };

        // Can't override if already set as a target.
        if (clonedElement.searchStatus === '' || clonedElement.searchStatus === 'not target') {
          clonedElement.searchStatus = searchStatus;
        }

        flatPrunedTree[elementId] = clonedElement;
      }

      if (clonedElement.parent) {
        if (overrideExpand) {
          clonedElement.parent.expanded = true;
        }
        if (!clonedElement.parent.children.find(child => child.elementId === elementId)) {
          clonedElement.parent.children.push(clonedElement);
          clonedElement.parent.children.sort(compareSiblings);
        }
      }

      return flatPrunedTree[elementId];
    };


    const match = (node, filter) => {
      if (!filter) {
        return '';
      }

      if (matchedNodes[node.elementId]) {
         return matchedNodes[node.elementId];
      }
      const parsedFilter = filter.toLowerCase();

      if (node.title.toLowerCase().includes(parsedFilter)) {
        return 'target: element';
      }

      if (node.asset) {
        const found = ['name', 'description', 'tags'].find(field => node.asset[field].toLowerCase().includes(parsedFilter));
        if (found) {
          return 'target: asset';
        }
      }

      if (node.schema && node.schema.strings) { // Don't have optional chaining in Node 14  :(
        if (Object.values(node.schema.strings).some(str => str.toLowerCase().includes(parsedFilter))) {
          return 'target: schema';
        }
      }

      if (node.scripts) {
        const scriptsArr = JSON.parse(node.scripts);
        console.log(`scripts: ${node.scripts}`);
        return scriptsArr.some((scriptId) => scriptsById[scriptId.id] && scriptsById[scriptId.id].name.toLowerCase().includes(parsedFilter))
          ? 'target: script' : false;
      }

      return '';
    };

    Object.keys(nodesById).forEach((elementId) => {
      const node = nodesById[elementId];
      const matchResult = match(node, filterString);

      if (elementId === 'root' || !filterString || matchResult) {
        // It goes in the pruned tree.
        clone(elementId, {
          overrideExpand: filterString !== '',
          searchStatus: matchResult
        });
      }
    });

    return {
      nodesById,
      treeData: [flatPrunedTree.root]
    };
  }

  duplicate(node) {
    const {
      info: { id: appId },
      scenes: [scene],
      duplicateElement,
      history,
      location
    } = this.props;

    const { elements } = scene;

    const element = elementById(elements, node.elementId);
    const { parent } = element;
    if (!parent) {
      return null;
    }

    const deepCopy = JSON.parse(JSON.stringify(elementDeepCopy(element)));
    // create unique ids
    const fixIds = (el) => {
      // eslint-disable-next-line no-param-reassign, no-multi-assign
      el.id = el.schema.strings.id = uuid();

      // eslint-disable-next-line no-restricted-syntax
      for (const child of el.children || []) {
        fixIds(child);
      }
    };

    fixIds(deepCopy);

    return duplicateElement({
      appId,
      sceneId: node.sceneId,
      parentId: parent.id,
      element: deepCopy
    }).then(() => {
      history.push({
        ...location,
        pathname: `/i/scene/${scene.id}/element/${deepCopy.id}`
      });
      window.bridge.send(3991, {
        sceneId: scene.id,
        elementId: deepCopy.id
      });
    });
  }

  copyElementToClipboard(node) {
    const { scenes: [scene] } = this.props;

    const { elements } = scene;
    const element = elementById(elements, node.elementId);

    // Ignore if the element is the root.
    const { parent } = element;
    if (!parent) {
      return null;
    }

    // copy the deepCopy to the clipboard
    const deepCopy = JSON.stringify(elementDeepCopy(element));
    navigator.clipboard.writeText(deepCopy);

    return null;
  }

  pasteElementFromClipboard(node) {
    const {
      info: { id: appId },
      scenes: [scene],
      duplicateElement,
      history,
      location
    } = this.props;

    const { elements } = scene;

    const element = elementById(elements, node.elementId);

    // get the JSON string from the clipboard
    navigator.clipboard.readText().then((copiedText) => {
      let deepCopy;
      try {
        deepCopy = JSON.parse(copiedText);
      } catch (e) {
        log.error('Error parsing copied text', e);
        return null;
      }
      // create unique ids
      const fixIds = (el) => {
        // eslint-disable-next-line no-param-reassign, no-multi-assign
        el.id = el.schema.strings.id = uuid();

        // eslint-disable-next-line no-restricted-syntax
        for (const child of el.children || []) {
          fixIds(child);
        }
      };

      fixIds(deepCopy);

      return duplicateElement({
        appId,
        sceneId: node.sceneId,
        parentId: element.id,
        element: deepCopy
      }).then(() => {
        history.push({
          ...location,
          pathname: `/i/scene/${scene.id}/element/${deepCopy.id}`
        });
        window.bridge.send(3991, {
          sceneId: scene.id,
          elementId: deepCopy.id
        });
      });
    });
  }

  addAsset(item, id) {
    const {
      info: { id: appId },
      scenes: [scene],
      addAssetToElement,
      match: {
        params: { elementId }
      }
    } = this.props;
    addAssetToElement({
      sceneId: scene.id,
      element: elementById(scene.elements, id),
      asset: item,
      oldAsset: undefined
    });
  }

  onNew(parentNode, type) {
    const {
      info: { id: appId },
      scenes: [scene],

      createElement,

      toggleNode,

      location,
      history
    } = this.props;

    const nodeId = uuid();
    createElement({
      appId,
      type,
      sceneId: parentNode.sceneId,
      parentId: parentNode.elementId,
      nodeId
    }).then(() => {
      history.push({
        ...location,
        pathname: `/i/scene/${parentNode.sceneId}/element/${nodeId}`
      });
      window.bridge.send(3991, {
        sceneId: scene.id,
        elementId: nodeId
      });
    });

    toggleNode(parentNode.elementId, true);
  }

  /**
   * Renders the object.
   */
  render() {
    const {
      isReadOnly,
      info: { id: appId },
      scenes: [scene],

      createElement,
      updateElement,
      deleteElement,
      toggleNode,
      setBoolean,
      setVisibility,
      addAssetToElement,

      connectDropTarget,

      location,
      history,
      classes,
      theme
    } = this.props;

    let {
      match: {
        params: { elementId }
      }
    } = this.props;

    const {
      deleteModal: { show: showDeleteModal, action: modalAction, node: nodeToDelete },
      filterString
    } = this.state;

    const nodeTitle = nodeToDelete ? `"${nodeToDelete.title}"` : 'this element';

    const hideModal = () => this.setState({
        deleteModal: {
          show: false,
          action: null,
          node: null
        }
      });
    const { treeData, nodesById } = this.appToTree(elementId);

    const select = (selectedNode, focus = false) => {
      const {
        sceneId, elementId: _elementId, isLocked, ancestorIsLocked
      } = selectedNode;
      window.bridge.send(
        focus ? 3992 : 3991,
        isLocked || ancestorIsLocked
          ? {}
          : {
              sceneId,
              elementId: _elementId
            }
      );
    };

    // If nothing in the app is otherwise selected, update to using the root element.
    if (location.pathname === '/') {
      elementId = 'root';
      window.bridge.send(focus ? 3992 : 3991, {
        sceneId: scene.id,
        elementId
      });
      history.push({
        ...location,
        pathname: `/i/scene/${scene.id}/element/root`
      });
    }

    const selectedNode = nodesById[elementId];

    // Only display Linked Component creation option for internal Enklu accounts
    if (createMenu.length < 5) {
      if (io.sails.user.email.includes('@enklu.com') || io.sails.user.email.includes('@createar.co')) {
        createMenu.splice(2, 0, linkedComponentMenuItem);
      }
    }

    return connectDropTarget(
      <div className={classes.container}>
        {isReadOnly && <Typography variant="h6">READ ONLY</Typography>}
        <Box p={1} display="flex" flexDirection="column" alignSelf="stretch">
          <BaseStandardTextField
            margin="dense"
            placeholder={'Filter'}
            value={filterString}
            onChange={ async ({ target: { value } }) => {
              this.setState({ filterString: value });
              this.setState({ matchedNodes : await this.newMatchedNodes(value) });
            }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Box flex={1} flexDirection="column" display="flex" position="relative">
            <Shortcuts
              className={'inherit-container'}
              targetNodeSelector={'body'}
              name={'ELEMENT'}
              handler={(action) => {
                if (selectedNode) {
                  switch (action) {
                    case 'FOCUS':
                      select(selectedNode, true);
                      break;

                    case 'ADJUST_POSITION':
                      window.bridge.send(3995, {});
                      break;

                    case 'ADJUST_ROTATION':
                      window.bridge.send(3996, {});
                      break;

                    case 'ADJUST_SCALE':
                      window.bridge.send(3997, {});
                      break;

                    case 'TRANSFORM_SPACE':
                      window.bridge.send(3998, {});
                      break;

                    case 'DUPLICATE': {
                      if (selectedNode) {
                        this.duplicate(selectedNode);
                      } else {
                        log.error('No selected node.');
                      }
                      break;
                    }

                    default:
                      break;
                  }
                }
              }}
            >
              <ConfirmationModal
                confirmIsDanger
                open={!!showDeleteModal}
                title="Delete"
                message={`Are you sure you want to delete ${nodeTitle}?`}
                onClose={hideModal}
                onConfirm={() => {
                  modalAction();
                  hideModal();
                }}
              />

              <SortableTree
                rowHeight={ROW_HEIGHT}
                theme={FileExplorerTheme}
                style={{
                  flex: 1,
                  height: 'auto'
                }}
                innerStyle={{
                  paddingLeft: theme.spacing(1),
                  height: 'auto',
                  zIndex: 2,
                  maxHeight: 'calc(100vh - 220px)',
                  outline: 'none'
                }}
                reactVirtualizedListProps={{ className: 'scrollable-both' }}
                treeData={treeData}
                slideRegionSize={200}
                canNodeHaveChildren={({ expanded, children }) => expanded || !children.length}
                generateNodeProps={({ node }) => ({
                  title: (
                    <ElementHierarchyItem
                      rowHeight={ROW_HEIGHT}
                      node={node}
                      onEdit={({ sceneId, elementId: _elementId }, { name }) => {
                        updateElement(appId, sceneId, _elementId, ActionSchemaTypes.STRING, 'name', name);
                      }}
                      onDuplicate={_node => this.duplicate(_node)}
                      onCopy={_node => this.copyElementToClipboard(_node)}
                      onPaste={_node => this.pasteElementFromClipboard(_node)}
                      onNew={(parentNode, type) => this.onNew(parentNode, type)}
                      onSelect={(_node) => {
                        const loc = `/i/scene/${_node.sceneId}/element/${_node.elementId}`;
                        const now = Date.now();

                        // ignore if we're already there
                        if (location.pathname === loc) {
                          if (now - lastFocus < doubleClickDelay) {
                            select(_node, true);
                          }

                          lastFocus = now;
                          return;
                        }

                        history.push({
                          ...location,
                          pathname: loc
                        });

                        // pass to bridge
                        select(_node);
                        lastFocus = now;
                      }}
                      onDelete={(_node) => {
                        this.setState({
                          ...this.state,
                          deleteModal: {
                            show: true,
                            node: _node,
                            action: () => deleteElement(appId, _node.sceneId, _node.elementId).then(() => {
                                // since this is async, _node and selectedNode may change
                                if (_node && selectedNode && _node.elementId === selectedNode.elementId) {
                                  history.push({
                                    ...location,
                                    pathname: '/'
                                  });
                                }
                              })
                          }
                        });
                      }}
                      onDrop={({ node: _node, item, type }) => {
                        if (type === DragDropTypes.ASSET) {
                          addAssetToElement({
                            sceneId: scene.id,
                            element: elementById(scene.elements, _node.elementId),
                            asset: item,
                            oldAsset: _node.asset
                          });
                        }
                      }}
                      onToggleLock={isLocked => setBoolean(appId, node, 'locked', isLocked)}
                      onToggleVisibility={ ({ isVisible, isLoaded }) => {
                        setVisibility(appId, node, isVisible, isLoaded);
                      }}
                    />
                  )
                })}
                canDrag={node => node.parentNode && !node.isEditable}
                canDrop={({ node, nextParent }) => nextParent && isValidRelationship(nextParent, node)}
                onChange={() => {}}
                onMoveNode={({
                  treeData, node, nextParentNode, prevPath, nextPath
                }) => {
                  // If the node didn't actually move, do nothing.
                  if (areArraysShallowEqual(prevPath, nextPath)) {
                    return;
                  }

                  const siblingActions = updateSiblings(treeData);
                  const { elementId: parentId } = nextParentNode;
                  const { sceneId } = node;

                  // move is special for managing undos
                  const redos = [
                    () => window.bridge.send(3990, {
                        sceneId: node.sceneId,
                        elementId: node.elementId,
                        parentId
                      })
                  ];

                  const undos = [
                    () => window.bridge.send(3990, {
                        sceneId: node.sceneId,
                        elementId: node.elementId,
                        parentId: parentByChildId(scene.elements, node.elementId).id
                      })
                  ];

                  window.bridge.send(3990, {
                    sceneId,
                    elementId: node.elementId,
                    parentId
                  });

                  if (siblingActions && siblingActions.length) {
                    txns.dispatchUpdate(sceneId, ...siblingActions);

                    redos.push(updateScene({ actions: siblingActions }, { appId, sceneId }));

                    const siblingUndos = txns.createUndos(siblingActions);
                    undos.push(updateScene({ actions: siblingUndos }, { appId, sceneId }));
                  }

                  undoManager.register(redos, undos);
                }}
                onVisibilityToggle={({ node, expanded }) => {
                  toggleNode(node.elementId, expanded);
                }}
              />
            </Shortcuts>

            <ElementHierarchyEmptySpace
              onDrop={(item, type) => {
                if (type === DragDropTypes.ASSET) {
                  const nodeId = uuid();
                  const appId = appId;
                  const { id: sceneId } = scene;
                  const parentId = 'root';
                  createElement({
                    appId,
                    undefined,
                    sceneId,
                    parentId,
                    nodeId
                  }).then((args) => {
                    history.push({
                      ...location,
                      pathname: `/i/scene/${sceneId}/element/${nodeId}`
                    });
                    window.bridge.send(3991, {
                      sceneId: scene.id,
                      elementId: nodeId
                    });
                    this.addAsset(item, nodeId);
                  });

                  toggleNode(parentId, true);
                }
              }}
            >
              <Box display="flex" flexDirection="row" p={1}>
                <Paper className={classes.buttonToolbar}>
                  <Tooltip title="Create Child">
                    <span>
                      <IconButton
                        disabled={!selectedNode}
                        onClick={(event) => {
                          this.setState({ createAnchorEl: event.currentTarget });
                        }}
                      >
                        <Add />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Menu
                    open={!!this.state.createAnchorEl}
                    anchorEl={this.state.createAnchorEl}
                    onClose={() => this.setState({ createAnchorEl: null })}
                  >
                    {createMenu.map((mi, i) => (
                      <MenuItem
                        disabled={mi.disabled}
                        key={i}
                        onClick={() => {
                          this.setState({ createAnchorEl: null });
                          this.onNew(selectedNode, mi.eventKey);
                        }}
                      >
                        {mi.title}
                      </MenuItem>
                    ))}
                  </Menu>

                  <Tooltip title="Duplicate Selected">
                    <span>
                      <IconButton
                        disabled={!selectedNode || !selectedNode.isRemovable}
                        onClick={() => {
                          this.duplicate(selectedNode);
                        }}
                      >
                        <FileCopy />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete Selected">
                    <span>
                      <IconButton
                        disabled={!selectedNode || !selectedNode.isRemovable}
                        onClick={() => {
                          this.setState({
                            deleteModal: {
                              show: true,
                              node: selectedNode,
                              action: () => deleteElement(appId, selectedNode.sceneId, selectedNode.elementId).then(() => {
                                  // since this is async, _node and selectedNode may change
                                  history.push({
                                    ...location,
                                    pathname: '/'
                                  });
                                })
                            }
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Paper>
              </Box>
            </ElementHierarchyEmptySpace>
          </Box>
        </Box>
      </div>
    );
  }
}

const target = {
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  canDrop(props, monitor) {
    return true;
  },

  // eslint-disable-next-line no-unused-vars
  hover(props, monitor, component) {},

  // eslint-disable-next-line no-unused-vars
  drop(props, monitor, component) {}
};

function collect(_connect, monitor) {
  return {
    connectDropTarget: _connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

const mapStateToProps = state => ({
  info: getAppInfo(state),
  scenes: getScenes(state),
  assetsById: getAllAssetsById(state),
  tree: getTree(state),
  libraries: getLibraries(state),
  isReadOnly: getAppIsReadOnly(state),
  scriptsById: getAllScriptsById(state),
});

const getRandomInt = () => Math.floor(Math.random() * 9007199254740992);

const mapDispatchToProps = dispatch => ({
  /**
   * Creates an element.
   */
  createElement: ({
 appId, type, sceneId, parentId, strings = {}, nodeId
}) => txns.request(sceneId, createAction(parentId, strings, type, nodeId)).then(() => {}),

  /**
   * Creates a duplicate element.
   */
  duplicateElement: ({
 appId, sceneId, parentId, element
}) => txns.request(sceneId, createDuplicateAction(parentId, element)),

  /**
   * Updates an element.
   */
  updateElement: (appId, sceneId, elementId, schemaType, key, value) => txns.request(sceneId, updateAction(elementId, schemaType, key, value)),

  /**
   * Deletes an element.
   */
  deleteElement: (appId, sceneId, elementId) => txns.request(sceneId, deleteAction(elementId)),

  /**
   * Makes sure an asset has been shared with app.
   */
  shareAssetWithApp: (appId, asset) => dispatch(shareassetwithapp({ assetId: asset.id }, { appId })),

  /**
   * Toggles a node open/shut
   */
  toggleNode: (node, parameters) => dispatch(_toggleNode(node, parameters)),

  /**
   * Toggles a boolean on a node.
   */
  setBoolean: (appId, { elementId, sceneId }, name, value) => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.BOOL, name, value)),

  /**
   * Sets the visibility of an element.
   */
  setVisibility: (appId, { elementId, sceneId }, isVisible, isLoaded) => txns.request(sceneId, updateAction(elementId, ActionSchemaTypes.BOOL, 'visible', isVisible), updateAction(elementId, ActionSchemaTypes.BOOL, 'loaded', isLoaded)),

  /**
   * Adds an asset to an element.
   */
  addAssetToElement: ({
 sceneId, element, asset, oldAsset
}) => dispatch(
      _addAssetToElement({
        sceneId,
        element,
        asset,
        oldAsset
      })
    ),
    onLoadScript: (scriptId, url) => dispatch(downloadscript(scriptId, url))
});

export default withTheme(
  withStyles(theme => ({
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    darkSearchField: {
      '& .MuiOutlinedInput-root': {
        boxShadow: textFieldShadow
      }
    },
    buttonToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(1),
      flex: 1
    }
  }))(
    compose(
      withRouter,
      connect(mapStateToProps, mapDispatchToProps),
      DropTarget('item', target, collect)
    )(ElementHierarchy)
  )
);
