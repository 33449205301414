import { getActions } from '@enklu/server-api';
import uuid from 'uuid';

import ElementTypes from '../../constants/ElementTypes';
import ActionTypes from '../../constants/ActionTypes';
import ActionSchemaTypes, { isValidSchemaType } from '../../constants/ActionSchemaTypes';
import LibraryTypes from '../../constants/LibraryTypes';
import txns from './TxnManager';
import { stringifyAssetSrc } from '../../util/util';
import { getLibraries } from '../selectors/assetLibrariesSelectors';
import { getAppInfo } from '../selectors/appSelectors';

const { shareassetwithapp } = getActions('trellis');
/**
 * Custom initializers per element type.
 */
const initializers = {
  [ElementTypes.QR_ANCHOR]: (definition) => {
    definition.data.schema.strings.name = 'Qr Anchor';

    // default qr value
    definition.data.schema.strings.qr_value = uuid();

    return definition;
  },

  [ElementTypes.CAPTION]: (definition) => {
    definition.data.schema.strings.name = 'Caption';

    definition.data.schema.ints = {
      fontSize: 20
    };

    definition.data.schema.floats = {
      width: 100
    };

    definition.data.schema.strings.overflow = 'Overflow';
    definition.data.schema.strings.alignment = 'MidCenter';

    return definition;
  },

  [ElementTypes.GROUP]: (definition) => {
    definition.data.schema.strings.name = 'Group';

    return definition;
  },

  [ElementTypes.LINKED_COMPONENT]: (definition) => {
    definition.data.schema.strings.name = 'Linked Component';
    definition.data.schema.strings.description = 'Can retrieve a static copy of an experience';

    return definition;
  },

  ['EXPERIENCE_ROOT_COPY']: (definition) => {
    definition.data.schema.strings.description = 'Static copy of experience';
    definition.data.overrideType = 'StaticLinkedExperienceRoot';
    definition.data.type = 0;
    return definition;
  },

  [ElementTypes.FLOAT]: (definition) => {
    definition.data.schema.strings.name = 'Float';
    definition.data.schema.strings.face = 'Camera';
    definition.data.schema.bools = {
      'focus.visible': false
    };

    return definition;
  },

  [ElementTypes.CONTENT]: (definition) => {
    definition.data.schema.strings.name = 'Asset';
    definition.data.schema.vectors = {
      scale: {
        x: 1,
        y: 1,
        z: 1
      }
    };
    definition.data.schema.bools = {
      'Group': false,
      'Networked': false,
      'Network Transform': true,
      ...(definition.data.schema.bools && definition.data.schema.bools)
    }
  },

  [ElementTypes.NETWORKED_TEXT]: (definition) => {
    definition.data.schema.strings.name = 'Text';
    definition.data.schema.vectors = {
      scale: {
        x: 1,
        y: 1,
        z: 1
      }
    };
    definition.data.schema.bools = {
      'Networked': false,
      'Network Transform': true
    }

    return definition;
  },

  [ElementTypes.NETWORKED_BUTTON]: (definition) => {
    definition.data.schema.strings.name = 'Button';
    definition.data.schema.vectors = {
      scale: {
        x: 1,
        y: 1,
        z: 1
      }
    };
    definition.data.schema.bools = {
      'Networked': false,
      'Network Transform': true
    }

    return definition;
  },

  // Bypass content defaults for elements generated from linked components
  ['LINKED_CONTENT']: (definition) => {
    definition.data.type = ElementTypes.CONTENT;

    return definition;
  },

  [ElementTypes.LIGHT]: (definition) => {
    definition.data.schema.strings.name = 'Light';
    definition.data.schema.strings.lightType = 'Directional';
    definition.data.schema.strings.shadows = 'None';
    definition.data.schema.floats = {
      intensity: 1
    };
    definition.data.schema.colors = {
      color: {
        r: 1,
        g: 1,
        b: 1,
        a: 1
      }
    };
  },

  // Bypass light defaults for elements generated from linked components
  ['LINKED_LIGHT']: (definition) => {
    definition.data.type = ElementTypes.LIGHT;

    return definition;
  },

  [ElementTypes.KINECT]: (definition) => {
    definition.data.schema.strings.name = 'Kinect';
  },

  [ElementTypes.IMAGE_ANCHOR]: (definition) => {
    definition.data.schema.strings.name = 'Image Anchor';
  },

  [ElementTypes.WORLD_ANCHOR]: (definition) => {
    definition.data.schema.strings.name = 'World Anchor';
    definition.data.schema.strings.definition = 'New World Anchor.';
    definition.data.schema.bools = {
      autoexport: true
    };
  },

  [ElementTypes.SCAN]: (definition) => {
    definition.data.schema.strings.name = 'Scan';
    definition.data.schema.strings.definition = 'New scan.';
  }
};

/**
 * Creates a create element action.
 * TODO Generalize.
 */
export function createAction(parent, strings, type, id) {
  const action = {
    type: ActionTypes.CREATE,
    parentId: parent || 'root',
    data: {
      id: id || uuid(),
      children: [],
      type: undefined === type ? ElementTypes.CONTENT : type,
      schema: {
        strings: {
          ...strings
        }
      }
    }
  };

  // special handling per type
  const initializer = initializers[action.data.type];
  if (initializer) {
    initializer(action);
  }

  return action;
}

/**
 * Creates a create element action for a full element.
 */
export function createFullElementAction(parent, schema, type, id, includeScriptIds, link = true) {
  let elementType = type;

  if (link) {
    switch (type) {
      case ElementTypes.CONTENT:
        elementType = 'LINKED_CONTENT';
        break;
      case ElementTypes.LIGHT:
        elementType = 'LINKED_LIGHT';
        break;
      default:
        break;
    }
  }

  const elementSchema = schema;
  elementSchema.strings.id = id;

  if (!includeScriptIds && elementSchema.strings.scripts) {
    elementSchema.strings.scripts = '[]';
  }

  const action = {
    type: ActionTypes.CREATE,
    parentId: parent || 'root',
    data: {
      id: id || uuid(),
      children: [],
      type: undefined === elementType ? ElementTypes.CONTENT : elementType,
      schema: {
        strings: {
          ...elementSchema.strings || null
        },
        ints: {
          ...elementSchema.ints || null
        },
        floats: {
          ...elementSchema.floats || null
        },
        bools: {
          ...elementSchema.bools || null
        },
        vectors: {
          ...elementSchema.vectors || null
        },
        colors: {
          ...elementSchema.colors || null
        }
      }
    }
  };

  // special handling per type
  const initializer = initializers[action.data.type];
  if (initializer) {
    initializer(action);
  }

  return action;
}

/**
 * Creates a createFromLinkedComponent element action.
 */
export function createFromLinkedComponentAction(parent, children, strings, name) {
  const action = {
    type: ActionTypes.CREATE,
    parentId: parent || 'root',
    data: {
      id: uuid(),
      children: children || [],
      type: ElementTypes.CONTENT,
      schema: {
        strings: {
          ...strings,
          name: name || 'New Entity'
        }
      }
    }
  };

  // special handling per type
  const initializer = initializers[action.data.type];
  if (initializer) {
    initializer(action);
  }

  return action;
}

/**
 * Creates a duplicate element.
 */
export function createDuplicateAction(parent, element) {
  const action = {
    type: ActionTypes.CREATE,
    parentId: parent || 'root',
    data: element
  };

  return action;
}

/**
 * Creates an update element action.
 */
export function updateAction(elementId, schemaType, key, value, defaultValue, metrics) {
  console.log("Creating update");
  console.trace();
  return {
    type: ActionTypes.UPDATE,
    elementId,
    schemaType,
    key,
    value,
    defaultValue,
    metrics
  };
}

/**
 * Creates a delete element action.
 */
export function deleteAction(elementId) {
  return {
    type: ActionTypes.DELETE,
    elementId
  };
}

/**
 * Updates an element when an asset has been added to it.
 *
 * @param scene
 * @param element
 * @param asset
 * @param oldAsset
 * @returns {Function}
 */
export const addAssetToElement = ({ sceneId, element, asset, oldAsset }) => (dispatch, getState) => {
  const {
    id: elementId,
    schema: {
      strings: { name: elementName } = {}
    } = {}
  } = element;
  const { id: assetId, name: assetName } = asset;
  const metrics = { owner: asset.owner, assetId: assetId }
  const actions = [
    updateAction(elementId, ActionSchemaTypes.STRING, 'assetSrc', stringifyAssetSrc(asset, 'latest'), null, metrics)
  ];

  // if the element still has the default name, set the name
  // equal to the asset name
  if (elementName === 'Asset' || (oldAsset && oldAsset.name === elementName)) {
    actions.push(updateAction(elementId, ActionSchemaTypes.STRING, 'name', assetName));
  }

  // is this asset shared with app?
  const state = getState();
  const libraries = getLibraries(state);
  const { id: appId } = getAppInfo(state);
  if (libraries[LibraryTypes.USER][assetId] && !libraries[LibraryTypes.APP][assetId]) {
    dispatch(shareassetwithapp({ assetId }, { appId }))
      .then(txns.request(sceneId, ...actions))
      .catch(() => console.log('TODO: Error popup!'));
  } else {
    txns.request(sceneId, ...actions);
  }
};
